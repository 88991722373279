import {FieldProps} from "ra-ui-materialui/src/field/types";
import {useRecordContext} from "react-admin";
import {Chip, Stack} from "@mui/material";
import React from "react";

export enum BlogItemStatusEnum {
    AWAITING_REVIEW = 0,
    UNDER_REVIEW = 1,
    SELECTED = 101,
    SELECTED_AND_PAID = 102,
    NOT_SELECTED_OR_RETURNED = -1,
}

const BlogItemStatusField =({source}: FieldProps) => {
    const record = useRecordContext();

    const redenderContentType = (status: number) => {
        switch (status) {
            case BlogItemStatusEnum.AWAITING_REVIEW:
                return '待審核';
            case BlogItemStatusEnum.UNDER_REVIEW:
                return '審核中';
            case BlogItemStatusEnum.SELECTED:
                return '入選';
            case BlogItemStatusEnum.SELECTED_AND_PAID:
                return '入選且已匯款';
            case BlogItemStatusEnum.NOT_SELECTED_OR_RETURNED:
                return '未入選/退件';
            default:
                return 'Unknown';
        }
    }

    return record ? (
        <Stack direction={"row"} spacing={0}>
            {
                record[source] === BlogItemStatusEnum.AWAITING_REVIEW && (
                    <Chip color={"info"} key={record.id} label={redenderContentType(record[source])} />
                )
            }
            {
                record[source] === BlogItemStatusEnum.UNDER_REVIEW && (
                    <Chip color={"warning"} key={record.id} label={redenderContentType(record[source])} />
                )
            }
            {
                record[source] === BlogItemStatusEnum.SELECTED && (
                    <Chip color={"success"} key={record.id} label={redenderContentType(record[source])} />
                )
            }
            {
                record[source] === BlogItemStatusEnum.SELECTED_AND_PAID && (
                    <Chip color={"secondary"} key={record.id} label={redenderContentType(record[source])} />
                )
            }
            {
                record[source] === BlogItemStatusEnum.NOT_SELECTED_OR_RETURNED && (
                    <Chip color={"error"} key={record.id} label={redenderContentType(record[source])} />
                )
            }
        </Stack>
    ) : null;

}

export default BlogItemStatusField;
