import {
    BooleanField, Count, CreateButton,
    DatagridConfigurable,
    DateField, DateInput, EmailField,
    FilterList, FilterListItem, FilterLiveSearch,
    List, Loading, SavedQueriesList, SelectColumnsButton,
    TextField,
    TopToolbar, useListContext
} from "react-admin";
import * as React from "react";
import {Card, CardContent, Divider, Tab, Tabs, Theme, useMediaQuery} from "@mui/material";
import {Fragment, useCallback} from "react";
import MobileGrid from "../orders/MobileGrid";
import BlogItemContentTypeField, {BlogItemContentTypeEnum} from "../components/BlogItemContentTypeField";
import BlogItemStatusField from "../components/BlogItemStatusField";
import BlogItemCategoryField, {BlogItemCategoryEnum} from "../components/BlogItemCategoryField";
import BlogCtrbItemsRowActions from "./BlogCtrbItemsRowActions";
import CategoryIcon from "@mui/icons-material/Bookmark";
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
        <SelectColumnsButton />
    </TopToolbar>
);

const PostList = () => (
    <List
        filterDefaultValues={{ status: 1 }}
        sort={{ field: 'createTime', order: 'DESC' }}
        perPage={10}
        actions={<ListActions />}
        filters={blogCtrbItemFilters}
        aside={<BlogCtrbItemFilterSidebar/>}
    >
        <TabbedDatagrid />
    </List>
);

const blogCtrbItemFilters = [
    <DateInput source="createTime" parse={d => new Date(d).getTime()} name="createTime" label="投稿時間"  key="createTime"/>,
    <DateInput source="lastUpdTime" parse={d => new Date(d).getTime()} name="lastUpdTime" label="最後更新時間" key="lastUpdTime" />
];

const BlogCtrbItemFilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 8, width: 250 }}>
        <CardContent>
            <SavedQueriesList icon={<SaveOutlinedIcon/>} />
            <FilterLiveSearch source="title" label="文章標題" name="title" />
            <FilterLiveSearch type={"email"} source="email" label="投稿人email" name="email" />
            <FilterList label="稿件種類" icon={<CategoryIcon />}>
                <FilterListItem label="文章" value={{contentType: BlogItemContentTypeEnum.NOTE}} />
                <FilterListItem label="書籤" value={{contentType: BlogItemContentTypeEnum.WEBLINK}} />
                <FilterListItem label="影片" value={{contentType: BlogItemContentTypeEnum.VIDEO}} />
                <FilterListItem label="YouTube" value={{contentType: BlogItemContentTypeEnum.YOUTUBE}} />
                <FilterListItem label="其他" value={{contentType: BlogItemContentTypeEnum.OTHER}} />
            </FilterList>
            <FilterList label="內容型態" icon={<MessageOutlinedIcon />}>
                <FilterListItem label="部落格" value={{ category: BlogItemCategoryEnum.BLOG }} />
                <FilterListItem label="工具介紹" value={{ category: BlogItemCategoryEnum.TOOL }} />
                <FilterListItem label="開箱文" value={{ category: BlogItemCategoryEnum.UNBOXING }} />
                <FilterListItem label="其他" value={{ category: BlogItemCategoryEnum.OTHER }} />
            </FilterList>
        </CardContent>
    </Card>
);

const tabs = [
    { id: -100, name: '全部' },
    { id: 0, name: '待审核' },
    { id: 1, name: '審核中' },
    { id: 101, name: '入選' },
    { id: 102, name: '入選且已匯款' },
    { id: -1, name: '未入選/退件' },
];

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const { filterValues, setFilters, displayedFilters, isPending, error } = listContext;
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
            setFilters(
                { ...filterValues, status: value },
                displayedFilters
            );
        },
        [displayedFilters, filterValues, setFilters]
    );

    if (isPending) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            <span>
                                {choice.name} (
                                <Count
                                    filter={{
                                        ...filterValues,
                                        status: choice.id,
                                    }}
                                    sx={{ lineHeight: 'inherit',color: 'red' }}
                                />
                                )
                            </span>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            {isXSmall ? (
                <MobileGrid />
            ) : (
                <>
                    {filterValues.status === -100 && (
                        <DatagridConfigurable rowClick={false}
                            bulkActionButtons={false}
                            omit={['createTime', 'summary', 'remark','remitMoney']}
                        >
                            <BlogItemContentTypeField source="contentType" label="稿件種類" />
                            <TextField source="title" label="文章標題"/>
                            <BlogItemCategoryField source="category" label="內容型態" />
                            <EmailField source="email" label="投稿人email"/>
                            <DateField source="lastUpdTime" sortBy="lastUpdTime" sortByOrder="DESC" showTime label="最後更新時間" />
                            <BlogItemStatusField source="status" label="審稿狀態" />
                            <DateField source="createTime" showTime label="投稿時間" />
                            <TextField source="summary" label="文章摘要"/>
                            <TextField source="remark" label="備註/退件原因"/>
                            <BooleanField source="remitMoney" label="已打款"/>
                            <BlogCtrbItemsRowActions label="操作" source="actions"/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 0 && (
                        <DatagridConfigurable rowClick={false}
                            bulkActionButtons={false}
                            omit={['createTime', 'summary', 'remark','remitMoney']}
                        >
                            <BlogItemContentTypeField source="contentType" label="稿件種類" />
                            <TextField source="title" label="文章標題"/>
                            <BlogItemCategoryField source="category" label="內容型態" />
                            <TextField source="email" label="投稿人email"/>
                            <DateField source="lastUpdTime" sortBy="lastUpdTime" sortByOrder="DESC" showTime label="最後更新時間" />
                            <BlogItemStatusField source="status" label="審稿狀態" />
                            <DateField source="createTime" showTime label="投稿時間" />
                            <TextField source="summary" label="文章摘要"/>
                            <TextField source="remark" label="備註/退件原因"/>
                            <BooleanField source="remitMoney" label="已打款"/>
                            <BlogCtrbItemsRowActions label="操作" source="actions"/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 1 && (
                        <DatagridConfigurable rowClick={false}
                                              bulkActionButtons={false}
                                              omit={['createTime', 'summary', 'remark','remitMoney']}
                        >
                            <BlogItemContentTypeField source="contentType" label="稿件種類" />
                            <TextField source="title" label="文章標題"/>
                            <BlogItemCategoryField source="category" label="內容型態" />
                            <TextField source="email" label="投稿人email"/>
                            <DateField source="lastUpdTime" sortBy="lastUpdTime" sortByOrder="DESC" showTime label="最後更新時間" />
                            <BlogItemStatusField source="status" label="審稿狀態" />
                            <DateField source="createTime" showTime label="投稿時間" />
                            <TextField source="summary" label="文章摘要"/>
                            <TextField source="remark" label="備註/退件原因"/>
                            <BooleanField source="remitMoney" label="已打款"/>
                            <BlogCtrbItemsRowActions label="操作" source="actions"/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 101 && (
                        <DatagridConfigurable rowClick={false}
                            bulkActionButtons={false}
                            omit={['createTime', 'summary', 'remark','remitMoney']}
                        >
                            <BlogItemContentTypeField source="contentType" label="稿件種類" />
                            <TextField source="title" label="文章標題"/>
                            <BlogItemCategoryField source="category" label="內容型態" />
                            <TextField source="email" label="投稿人email"/>
                            <DateField source="lastUpdTime" sortBy="lastUpdTime" sortByOrder="DESC" showTime label="最後更新時間" />
                            <BlogItemStatusField source="status" label="審稿狀態" />
                            <DateField source="createTime" showTime label="投稿時間" />
                            <TextField source="summary" label="文章摘要"/>
                            <TextField source="remark" label="備註/退件原因"/>
                            <BooleanField source="remitMoney" label="已打款"/>
                            <BlogCtrbItemsRowActions label="操作" source="actions"/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === 102 && (
                        <DatagridConfigurable rowClick={false}
                            bulkActionButtons={false}
                            omit={['createTime', 'summary', 'remark','remitMoney']}
                        >
                            <BlogItemContentTypeField source="contentType" label="稿件種類" />
                            <TextField source="title" label="文章標題"/>
                            <BlogItemCategoryField source="category" label="內容型態" />
                            <TextField source="email" label="投稿人email"/>
                            <DateField source="lastUpdTime" sortBy="lastUpdTime" sortByOrder="DESC" showTime label="最後更新時間" />
                            <BlogItemStatusField source="status" label="審稿狀態" />
                            <DateField source="createTime" showTime label="投稿時間" />
                            <TextField source="summary" label="文章摘要"/>
                            <TextField source="remark" label="備註/退件原因"/>
                            <BooleanField source="remitMoney" label="已打款"/>
                            <BlogCtrbItemsRowActions label="操作" source="actions"/>
                        </DatagridConfigurable>
                    )}
                    {filterValues.status === -1 && (
                        <DatagridConfigurable rowClick={false}
                            bulkActionButtons={false}
                            omit={['createTime', 'summary', 'remark','remitMoney']}
                        >
                            <BlogItemContentTypeField source="contentType" label="稿件種類" />
                            <TextField source="title" label="文章標題"/>
                            <BlogItemCategoryField source="category" label="內容型態" />
                            <TextField source="email" label="投稿人email"/>
                            <DateField source="lastUpdTime" sortBy="lastUpdTime" sortByOrder="DESC" showTime label="最後更新時間" />
                            <BlogItemStatusField source="status" label="審稿狀態" />
                            <DateField source="createTime" showTime label="投稿時間" />
                            <TextField source="summary" label="文章摘要"/>
                            <TextField source="remark" label="備註/退件原因"/>
                            <BooleanField source="remitMoney" label="已打款"/>
                            <BlogCtrbItemsRowActions label="操作" source="actions"/>
                        </DatagridConfigurable>
                    )}
                </>
            )}
        </Fragment>
    );
};

export default PostList;
